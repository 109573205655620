
import pattern from '~/mixins/pattern.js';
export default {
    props : ['blok'],
    mixins: [pattern],
    data() {
        return {
            searchField : {
                name: 'search',
                placeholder: this.$__('generalSearchFieldPlaceholder'),
                type: 'text',
                required: true,
                value : null,
                error: false
            },
        }
    },
     methods:{
        initSearch(){
            if(this.searchField.value == null || this.searchField.value == ''){
                this.searchField.error = true
            }else{
                this.$router.push({
                    path: this.$__url('/products'),
                    query: {
                        s: this.searchField.value
                    }
                })
            }
        }
    }
}
